import React, { useState, useContext } from 'react'
import { formatTemplateData } from '@utils/templates'
import Container from '@layout/Container'
import { ComponentBase } from '@mixins/MixinsComponent'
import ComponentContent from '@blocks/ComponentContent'
import styled, { css } from 'styled-components'
import { tokens, spacing, box, media } from '@tokens'
import { useForm, FormContext, useFormContext } from 'react-hook-form'
import { ThemeContext } from '@layout/Layout'
import { ButtonStyles } from '@mixins/MixinsButton'
import Arrow from '@svgs/Arrow'
import Field from '@components/Form/Fields'
import Loader from '@elements/Loader'
import { LargeTypeSizes } from '@styles/Typography'

export const NewsletterContainer = styled.div`
  ${ComponentBase}
  width: 100%;

  > .inner {
    ${box({
      spacing: 'top',
      multiplier: -1,
      type: 'margin',
    })};
    max-width: 32rem;
  }

  .form {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
  }

  .field {
    width: 100%;
  }


  .footer-newsletter-heading {
    ${LargeTypeSizes}
    margin-bottom: ${spacing(3)};
    
    ${media.greaterThan('s')`
      margin-bottom: ${spacing(5)};
    `}
  }

  .form-footer {
    position: relative;
    align-self: flex-start;
  }

  .loader {
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -0.5rem;
    width: 2.5rem;
    margin-left: 1rem;
  }

  .form-error {
    color: ${tokens.color('error')};
    text-align: left;
    margin-left: 0;
    margin-right: auto;
  }
`
const Button = styled.button`
  ${ButtonStyles}
  margin-top: ${spacing(2)};
  

  &[disabled] {
    opacity: 0.4;
  }
`

const NewsletterPage = () => {
  const { theme } = useContext(ThemeContext)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)
  const [formError, setFormError] = useState('')

  const title = 'Newsletter'

  const heroFields = {
    textContent: {
      selectedFields: ['heading'],
      heading: ' ',
    },
    hasSocial: false,
  }

  const template = {
    title,
    heroFields,
    designations: ['newsletter'],
    heroType: 'post',
    theme: 'dark',
  }


  const fields = [, {
    label: 'Your first name',
    name: 'first_name',
    type: 'text',
  }, {
      label: 'Your last name',
      name: 'last_name',
      type: 'text',
    }, {
      label: 'Your fancy email',
      name: 'email',
      type: 'email',
      isRequired: true,
    }]


  const methods = useForm({
    validateCriteriaMode: 'all',
  })
  
  const { handleSubmit } = methods

  const onSubmit = async (data) => {
    setFormError('')
    setIsLoading(true)

    const { email, first_name, last_name } = data

    const createdResponse = await fetch('https://us-west1-hoyne-brewing-admin-key.cloudfunctions.net/klaviyo-create-update', {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify({
        email,
        first_name,
        last_name
      })
    })
    const isCreated = await createdResponse.json()

    if (isCreated?.errors) {
      setIsLoading(false)
      setFormError('There was an error creating your profile. Check the console for more information')
      console.error(isCreated.errors)
      return undefined
    }

    const subscribedResponse = await fetch('https://us-west1-hoyne-brewing-admin-key.cloudfunctions.net/klaviyo-subscribe', {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify({
        email,
        list_id: 'RDfszW'
      })
    })

    const isSubscribed = await subscribedResponse.json()

    if (isSubscribed?.errors) {
      setIsLoading(false)
      setFormError('There was an error subscribing your profile. Check the console for more information')
      console.error(isSubscribed?.errors)
      return undefined
    }
    
    setIsLoading(false)
    setHasSubmit(true)
  }

  return (
    <Container template={formatTemplateData(template)}>
      <NewsletterContainer size='small'>
        <div className="inner">
          <h2 className="">Join Hoyne!</h2>
          <p className="footer-newsletter-heading">
            Sign up for our newsletter to get first access to new releases, events, behind the scenes looks, Q&A with our brewers, and more!
          </p>

          {!hasSubmit ? (<FormContext {...methods}>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              {fields.map(field =>
                <Field
                  key={field.name}
                  className="field"
                  {...field}
                  theme={theme}
                />
              )}
              <div className='form-footer'>
                <Button type="submit" theme={theme} large disabled={isLoading} >
                  Sign Up
                  <Arrow className="arrow" />
                </Button>
                <Loader className='loader' isLoading={isLoading} size={8} />
              </div>
              {formError && <p className='form-error'>{formError}</p>}
            </form>
          </FormContext>) : (<ComponentContent
            className="confirmation-message"
            textContent={{
              selectedFields: ['content'],
              content: 'Thank you! Check your inbox for confirmation!',
            }}
          />)}
        </div>
      </NewsletterContainer>
    </Container>
  )
}

export default NewsletterPage
